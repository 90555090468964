<template>
  <layout class="leaderboard" v-load="loading">
    <div class="row">
      <div class="col-md-6">
        <h2>Sent</h2>
        <h5>Regular colleagues</h5>
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="sentFeedbacks.filter(item => item.is_regular_colleage).length > 0">
              <b-table hover :items="sentFeedbacks.filter(item => item.is_regular_colleage)" :fields="tablesFields.sent" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(last_date)="data">
                  <div v-html="getDataStatus(data.item.last_date, true)"></div>
                </template>
                <template #cell(total)="data">
                  <a href="#" @click.prevent="moveToPage('sent', data.item.recipient)" v-if="data.item.total > 0">{{ data.item.total }}</a>
                  <span v-else>-</span>
                </template>
                <template #cell(action)="data">
                  <a href="#" @click.prevent="openModal(data.item.recipient)">New feedback</a>
                </template>
              </b-table>
            </div>
            <div v-else>
              <b-table :fields="tablesFields.sent" id="account-table" hover />
              <EmptyPage/>
            </div>
          </div>
        </div>
        <h5>Other colleagues</h5>
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="sentFeedbacks.filter(item => !item.is_regular_colleage).length > 0">
              <b-table :items="sentFeedbacks.filter(item => !item.is_regular_colleage)" hover :fields="tablesFields.sent" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(last_date)="data">
                  <div v-html="getDataStatus(data.item.last_date, false)"></div>
                </template>
                <template #cell(total)="data">
                  <a href="#" @click.prevent="moveToPage('sent', data.item.recipient)" v-if="data.item.total > 0">{{ data.item.total }}</a>
                  <span v-else>-</span>
                </template>
                <template #cell(action)="data">
                  <a href="#" @click.prevent="openModal(data.item.recipient)">New feedback</a>
                </template>
              </b-table>
            </div>
                        <div v-else>
                          <b-table :fields="tablesFields.sent" id="account-table" hover />
                          <EmptyPage/>
                        </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>Received</h2>
        <h5>Regular colleagues</h5>
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="receivedFeedbacks.filter(item => item.is_regular_colleage).length > 0">
              <b-table :items="receivedFeedbacks.filter(item => item.is_regular_colleage)" hover :fields="tablesFields.received" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(last_date)="data">
                  <div v-html="getDataStatus(data.item.last_date, true)"></div>

                </template>
                <template #cell(total)="data">
                  <a href="#" @click.prevent="moveToPage('received', data.item.author)" v-if="data.item.total > 0">{{ data.item.total }}</a>
                  <span v-else>-</span>
                </template>
              </b-table>
            </div>
            <div v-else>
              <b-table :fields="tablesFields.received" id="account-table" hover />
              <EmptyPage/>
            </div>
          </div>
        </div>
        <h5>Other colleagues</h5>
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="receivedFeedbacks.filter(item => !item.is_regular_colleage).length > 0">
              <b-table hover :items="receivedFeedbacks.filter(item => !item.is_regular_colleage)" :fields="tablesFields.received" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(last_date)="data">
                  <div v-html="getDataStatus(data.item.last_date, false)"></div>
                </template>
                <template #cell(total)="data">
                  <a href="#" @click.prevent="moveToPage('received', data.item.author)" v-if="data.item.total > 0">{{ data.item.total }}</a>
                  <span v-else>-</span>
                </template>
              </b-table>
            </div>
                        <div v-else>
                          <b-table :fields="tablesFields.received" id="account-table" hover />
                          <EmptyPage/>
                        </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        id="send-modal"
        size="md"
        centered
        scrollable
        title-class="font-22"
        title="Send feedback"
        hide-footer
    >
      <b-form
          class="purchase-form"
      >
        <form action="" @submit.prevent="sendFeedbacks">
          <div class="form-group">
            <label for="">Selected employee</label>
            <input type="text" class="form-control" disabled :value="sendForm.employee?.name ?? 'Не найдено'">
          </div>
          <div class="form-group">
            <div class="d-flex">
              <button class="btn btn-outline-success mr-2" :class="{'active': sendForm.type_id === 1}" @click.prevent="sendForm.type_id = 1">Positive</button>
              <button class="btn btn-outline-secondary mr-2" :class="{'active': sendForm.type_id === 2}" @click.prevent="sendForm.type_id = 2">Neutral</button>
              <button class="btn btn-outline-danger" :class="{'active': sendForm.type_id === 3}" @click.prevent="sendForm.type_id = 3">Negative </button>
            </div>
          </div>
          <div class="error-block" v-if="!$v.sendForm.type_id.required && $v.sendForm.type_id.$dirty">
            <p class="text-danger">Type required</p>
          </div>
          <div class="form-group">
            <textarea name="" id="" cols="30" rows="3" class="form-control" v-model="sendForm.text" @blur="saveText(sendForm.employee?.uid)"></textarea>
          </div>
          <div class="error-block" v-if="(!$v.sendForm.text.required && $v.sendForm.text.$dirty) || (!$v.sendForm.text.minLength && $v.sendForm.text.$dirty)">
            <p class="text-danger">Text required and must be more 10 length</p>
          </div>
          <div class="d-flex mt-4 justify-content-end">
            <div>
              <b-button variant="primary" disabled v-if="loading">
                <b-spinner small type="grow"></b-spinner>
                Sending...
              </b-button>
              <b-button
                  type="submit"
                  variant="primary"
                  v-else
              >
                Send
              </b-button>
            </div>
          </div>
        </form>
      </b-form>
    </b-modal>
  </layout>
</template>

<script>
import EmptyPage from '@/components/empty-page';
import {showErrorToast} from "../../../utils/toasts";
import {convertObjectToFormData} from "../../../utils/converters";

import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "RegularDashboard",
  data () {
    return {
      tablesFields: {
        sent: [
          {
            key: '#',
            label: '#'
          },
          {
            key: 'recipient.name',
            label: 'Employee'
          },
          {
            key: 'last_date',
            label: 'Last Feedback'
          },
          {
            key: 'total',
            label: '# reviews'
          },
          {
            key: 'action',
            label: 'Action'
          },
        ],
        received: [
          {
            key: '#',
            label: '#'
          },
          {
            key: 'author.name',
            label: 'Employee'
          },
          {
            key: 'last_date',
            label: 'Last Feedback'
          },
          {
            key: 'total',
            label: '# reviews'
          }
        ]
      },
      sendForm: {
        employee: null,
        type_id: null,
        text: null
      }
    }
  },
  components: {
    EmptyPage
  },
  validations: {
    sendForm: {
      employee: { required },
      type_id: { required },
      text: { required, minLength: minLength(10) }
    }
  },
  methods: {
    async moveToPage(path, user) {
      const userFilter = () => {
        switch (path) {
          case 'sent':
            return {
              userTo: {
                name: user?.name,
                uid: user?.uid
              }
            }
          case 'received':
            return {
              userFrom: {
                name: user?.name,
                uid: user?.uid
              }
            }
        }
      }
      const filter = {
        date: [],
        department: null,
        search: '',
        ...userFilter()
      }
      await this.$store.commit(`weeklyFeedback/${path === 'sent' ? 'SET_FILTER_FOR_SENT' : 'SET_FILTER_FOR_RECEIVED'}`, filter)
      await this.$router.push(`/weekly-feedback/${path}`)
    },
    saveText(uid) {
      this.$store.commit('weeklyFeedback/UPDATE_SAVED_FEEDBACKS', { uid: uid, text: this.sendForm.text })
    },
    async clearSendForm() {
      for (const [key] of Object.entries(this.sendForm)) {
        this.sendForm[key] = null
      }
      this.$v.sendForm.$reset()
    },
    updateSendForm (employee) {
      this.sendForm.employee = employee
      this.sendForm.text = this.savedFeedbacks.find(el => el.uid === employee?.uid)?.text
    },
    openModal (employee) {
      this.$v.sendForm.$reset()
      this.clearSendForm()
      this.updateSendForm(employee)
      this.$bvModal.show('send-modal')
    },
    async sendFeedbacks () {
      if (this.$v.sendForm.$invalid) {
        this.$v.sendForm.$touch()
        showErrorToast('Please, fill the form with valid data')
      } else {
        const obj = {
          'recipient_uid[0]':this.sendForm.employee?.uid,
          'type_id[0]':this.sendForm.type_id,
          'text[0]':this.sendForm.text,
        }
        await this.$store.dispatch('weeklyFeedback/storeFeedback', convertObjectToFormData({
          ...obj,
          _method: 'POST'
        })).then(async (res) => {
          if (res) {
            await this.$store.dispatch('weeklyFeedback/getAllSentFeedback');
            await this.$store.dispatch('notifications/getNotificationChecksAndCounts');
            await this.$store.commit('weeklyFeedback/DELETE_SAVED_FEEDBACK', this.sendForm.employee?.uid)
            await this.clearSendForm()
            this.$bvModal.hide('send-modal')
          }
        })
      }
    },
    getDataStatus(date, isRegular) {
      const mDate = this.$moment(date).utc(true)
      if (mDate.isValid()) {
        const today = this.$moment()
        const diff = today.diff(mDate, 'days')
        if (diff >= 7 && isRegular) {
          return `${mDate.fromNow()} <span class="text-danger">(!)</span>`
        } else {
          return `${mDate.fromNow()}`
        }
      } else {
        return 'Never <span class="text-danger">(!)</span>'
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.weeklyFeedback.loading;
    },
    receivedFeedbacks () {
      return this.$store.state.weeklyFeedback.dashboard.receivedFeedbacks
    },
    sentFeedbacks () {
      return this.$store.state.weeklyFeedback.dashboard.sentFeedbacks
    },
    savedFeedbacks () {
      return this.$store.state.weeklyFeedback.dashboard.savedFeedbacks
    }
  },
  async mounted () {
    await this.$store.dispatch('weeklyFeedback/getAllSentFeedback')
    await this.$store.dispatch('weeklyFeedback/getAllReceivedFeedback')
  }
}
</script>

<style scoped>
.table {
  margin-bottom: 0;
}
</style>
